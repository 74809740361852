import React, { Component } from 'react';
import {  withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

import {fetchMyCompany,updateCompany} from '../../actions/companysAction';

import { Segment, Form, Button,  Header } from 'semantic-ui-react';
import TextInput from '../form/TextInput';
import TextArea from '../form/TextArea';
import SelectInput from '../form/SelectInput';
import DateInput from '../form/DateInput';

import {  typeOfProp, typeOfDealings,locality, } from './Data';
import {
  //composeValidators,
  combineValidators,
  isRequired,
} from 'revalidate';
import {isValidEmail,isValidPhone,} from "../validator/revalidator";

const validate = combineValidators({
  
  //*********company */
  mobileCP1:isValidPhone({}),
  mobileCP2:isValidPhone({}),
  cemail:isValidEmail({}),
  emailCP1:isValidEmail({}),
  emailCP2:isValidEmail({}),
 
  cname:isRequired('Company Name') ,
  locality:isRequired('Locality') ,
  //city:isRequired('City/District') ,
  
});

class CompanyUpdate extends Component {

  componentDidMount() {
    this.props.fetchMyCompany();
    const datePickers = document.getElementsByClassName("react-datepicker__input-container");
    Array.from(datePickers).forEach((el => el.childNodes[0].setAttribute("readOnly", true)))
 }
  onSubmit = values => {
    const { id } = this.props.match.params;
    this.props.updateCompany(id, values, this.props.history);
  };
  backButtonHandler =()=>{
    this.props.history.goBack()
  }
  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;
    return (
      <div style={{maxWidth:'700px',margin:'auto'}}>

           
        <Segment
          textAlign="center"
          attached
          inverted
          color="teal"
          style={{ border: "none" }}
        >
        <Header
               // color="blue"
               content="Edit Company"
              />
        </Segment>
        <div className="card-form ">
          <div className=" label" >

            <Form
              style={{ padding: 10 }}
              onSubmit={handleSubmit(this.onSubmit)}
            >

            <Button
            color="teal"
            type="button"
            onClick={()=>this.backButtonHandler()}

          >
            Cancel
          </Button>
            <Button
              color="orange"
              type="button"
              disabled={pristine || submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <br /><br />

              <label >Company's Phone1</label>
              <Field
                name="cmobile1"
                type="text"
                component={TextInput}
                placeholder="Company's Phone1"
              />
              <label >Company's Phone2</label>
              <Field
                name="cmobile2"
                type="text"
                component={TextInput}
                placeholder="Company's Phone2"
              />
              <label >Company's Email</label>
              <Field
                name="cemail"
                type="text"
                component={TextInput}
                placeholder="Company's Email"
              />
              <label >Company's Website</label>
              <Field
                name="website"
                type="text"
                component={TextInput}
                placeholder="Company's Website"
              />
              <label>Company's RERA No.</label>
            <Field
              name="reraNo"
              type="text"
              component={TextInput}
              placeholder="Enter RERA Number"
            />
              <label >Operating Since</label>
              <Field
                name="operatingSince"
                type="text"
                component={DateInput}
                dateFormat="dd-MM-yyyy"
                placeholder="Operating Since"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
              <label >Type of Dealings</label>
              <Field
                //search={true}
                multiple={true}
                name="typeOfDealings"
                type="text"
                component={SelectInput}
                options={typeOfDealings()}
                placeholder="Type of Dealings"
              />
              <label >Types of properties deals in</label>
              <Field
                //search={true}
                multiple={true}
                name="typeOfPropertyDealsIn"
                type="text"
                component={SelectInput}
                options={typeOfProp()}
                placeholder="Types of properties deals in"
              />
            <label>Address</label>{' '}<span style={{color:'red'}}><b>*</b></span>
            <Field
              name="address"
              type="text"
              component={TextInput}
              placeholder="Address"
            />
              <label >Locality</label>{' '}<span style={{color:'red'}}><b>*</b></span>
            <Field
              name="locality"
              placeholder="Locality"
              type="text"
              options={locality()}
              //search={true}
              component={SelectInput}
            />
              {/* <label >City/District</label>
            <Field
              //search={true}
              name="city"
              type="text"
              component={SelectInput}
              options={city()}
              placeholder="City/District"
            />
            <label >State</label>
            <Field
              //search={true}
              name="state"
              type="text"
              component={SelectInput}
              options={state}
              placeholder="State"
            /> */}
              <label >Pincode</label>
              <Field
                name="pincode"
                type="text"
                component={TextInput}
                placeholder="Pincode"
              />
              <label >Tell us about your Company</label>
              <Field
                name="description"
                type="text"
                component={TextArea}
                rows={3}
                placeholder="Tell us about your Company"
              />
              {/* <label >Description For Advertisement</label>
              <Field
                name="noteForAd"
                type="text"
                component={TextArea}
                rows={3}
                placeholder="Description For Advertisement"
              /> */}
              <Header sub color="teal" content="Contact Persons Details" />
              <label >First Contact person's Name</label>
              <Field
                name="nameCP1"
                type="text"
                component={TextInput}
                placeholder="First Contact person's Name"
              />
              <label >First Contact person's Mobile</label>
              <Field
                name="mobileCP1"
                type="text"
                component={TextInput}
                placeholder="First Contact person's Mobile"
              />
              {/* <label >First Contact person's Email</label>
              <Field
                name="emailCP1"
                type="text"
                component={TextInput}
                placeholder="First Contact person's Email"
              /> */}
              <label >First Contact person's Designation</label>
              <Field
                name="designationCP1"
                type="text"
                component={TextInput}
                placeholder="First Contact person's Designation"
              />
              <label >Second Contact person's Name</label>
              <Field
                name="nameCP2"
                type="text"
                component={TextInput}
                placeholder="Second Contact person's Name"
              />
              <label >Second Contact person's Mobile</label>
              <Field
                name="mobileCP2"
                type="text"
                component={TextInput}
                placeholder="Second Contact person's Mobile"
              />
              {/* <label >Second Contact person's Email</label>
              <Field
                name="emailCP2"
                type="text"
                component={TextInput}
                placeholder="Second Contact person's Email"
              /> */}
              <label >Second Contact person's Designation</label>
              <Field
                name="designationCP2"
                type="text"
                component={TextInput}
                placeholder="Second Contact person's Designation"
              />
              {/* <Header sub color="teal" content="User's Details" />
            <Field
              name="uname"
              type="text"
              component={TextInput}
              placeholder="User Name"
            />
            <Field
              name="uid"
              type="text"
              component={TextInput}
              placeholder="User Mobile as user ID"
            />
            <Field
              name="uemail"
              type="email"
              component={TextInput}
              placeholder="User Email"
            />
            <Field
              name="password"
              type="password"
              component={TextInput}
              placeholder="Password"
            />
            <Field
              name="password2"
              type="password"
              component={TextInput}
              placeholder="Confirm Password"
            /> */}

              <br />

              <Button
                color="olive"
                type="submit"
                disabled={pristine || submitting}
              >
                Submit
              </Button>
              <Button
                color="orange"
                type="button"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Clear Values
              </Button>
            </Form>

            <br/> <br/> <br/>
                        </div>
                        </div>
                        </div>
    );
  }
}

export default connect(
  state => {
  
    return { initialValues: state.companys.company }; // pull initial values from account reducer
  },
  {fetchMyCompany,updateCompany}
)(
  reduxForm({
    form: 'EditCompanyForm', // a unique identifier for this form
    validate
  })(withRouter(CompanyUpdate))
);
