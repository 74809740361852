import React, {useEffect} from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";


import {fetchContactReqsForDealer} from "../../actions/contactReqAction";
import { closeModal } from "../../actions/modalAction";
import {  Form, Button,} from "semantic-ui-react";

import DateInput from "../form/DateInput";





const SearchContactReqsForDealer = props => {
  useEffect(() => {
    const datePickers = document.getElementsByClassName("react-datepicker__input-container");
    Array.from(datePickers).forEach((el => el.childNodes[0].setAttribute("readOnly", true)))
    },[])
    
  const { handleSubmit,reset,  submitting } = props;

  const onSubmit = values => {
    const offset = 0
const { limit} = props.state.contactReqs;
    props.fetchContactReqsForDealer(values, limit,offset);
  };
  return (
    <div style={{maxWidth:'700px',margin:'auto'}}>

    {/**/}
    <div className='margin-auto-H' >
          <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
            {/*  */}
            Search Contact Request Received
        {/*  */}
        </div>
        </div>
        {/**/}
      
        <div className="card-form mt-10">
          <div  >

          <Form
          onSubmit={handleSubmit(onSubmit)}
          >
 #  Leave All Field Blank (Clear Values) to View All Contact Request Received 
           <br /><br />
            
          <Button
          color="teal" 
          type="button"
          onClick={()=>props.closeModal()}

          >
          Cancel
          </Button>

          <Button 
          color="orange"
          type="button"
          disabled={ submitting}
          onClick={reset}
          >
          Clear Values
          </Button>
          <Button
          color="blue"
          type="submit"
          disabled={submitting}
          >
          Search
          </Button>
         
            <br/><br/>
            {/*  */}
            <label >Lower Date</label>
            <Field
              name="createdAtL"
              type="text"
              component={DateInput}
              placeholder= "Lower Date To Search Contact Request Between Two Dates"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            <label >Higher Date</label>
            <Field
              name="createdAtH"
              type="text"
              component={DateInput}
              placeholder="Higher Date To Search Contact Request Between Two Dates"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />




            <br />

            <Button
              color="blue"
              type="submit"
              disabled={submitting}
            >
              Search
            </Button>
            <Button
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="teal"
              type="button"
              onClick={()=>props.closeModal()}

            >
              Cancel
            </Button>
          </Form>
          <br/>
            </div>
            </div>
            </div>
  );
};
const mapStateToProps = state => {
  return {
    state
  };
};
export default connect(
  mapStateToProps,
   {fetchContactReqsForDealer,closeModal}
)(
  reduxForm({
    form: "SearchContactReqsForDealer", // a unique identifier for this form
    destroyOnUnmount: false
    // validate
  })(SearchContactReqsForDealer)
);
