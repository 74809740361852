import React, {useEffect} from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";


import {searchProjectRs} from "../../actions/projectRAction";
import { closeModal } from "../../actions/modalAction";
import {  Form, Button, } from "semantic-ui-react";
//import TextInput from "../form/TextInput";
import SelectInput from "../form/SelectInput";
import DateInput from "../form/DateInput";
//import { projectName } from "./DataProjectName";
import {
  
  category,
  // city,
  // state,
  configuration,
  status,
  
  ageOfConstruction,
  locality,
 
} from "./Data";


const ProjectRSearch = props => {
  useEffect(() => {
    const datePickers = document.getElementsByClassName("react-datepicker__input-container");
    Array.from(datePickers).forEach((el => el.childNodes[0].setAttribute("readOnly", true)))
    },[])
    
  const { handleSubmit,  reset, submitting } = props;

const projectName = () => {
  return props.state.projectRs.projectRsForData.map(value => ({
    key: value.projectName,
    text: `${value.projectName}` ,
    value: value.projectName,style:{fontSize:'18px'}
  }));
};
  const onSubmit = values => {
    const offset = 0
const { limit} = props.state.projectRs;
    props.searchProjectRs(values, limit,offset);
  };
  return (
    <div style={{maxWidth:'700px',margin:'auto'}}>

      {/**/}
      <div className='margin-auto-H' >
            <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
              {/*  */}
              Search Residential Projects
          {/*  */}
          </div>
          </div>
          {/**/}
        
          <div className="card-form mt-10">
          <div  >

          <Form 
          onSubmit={handleSubmit(onSubmit)}
          >
             #  Leave All Field Blank (Clear Values) to View All Available Projects 
           <br /><br />


            <Button
            color="teal"
            type="button"
            onClick={()=>props.closeModal()}

            >
            Cancel
            </Button>
            
            <Button
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="blue"
              type="submit"
              disabled={submitting}
            >
              Search
            </Button>
          <br /><br />
          {/* <label >Sort By</label>
          <Field
            //search={true}
            name="sorting"
            type="text"
            component={SelectInput}
            options={sorting()}
            placeholder="Sort By"
          /> */}

            <label >Category</label>
            <Field
              name="categoryA"
              placeholder="Category"
              type="text"
              options={category()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />

            <label >Configuration</label>
            <Field
              name="configurationA"
              placeholder="Configuration"
              type="text"
              options={configuration()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label >Status</label>
            <Field
              name="statusA"
              placeholder="Status"
              type="text"
              options={status()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />

            <label >Age Of Construction</label>
            <Field
              name="ageOfConstruction"
              placeholder="Age Of Construction"
              type="text"
              options={ageOfConstruction()}
              //search={true}
              //multiple={true}
              component={SelectInput}
            />

            {/* <label >Project/Complex Name</label>
            <Field
              name="projectNameA"
              placeholder="Project/Complex Name"
              type="text"
              options={projectName()}
              search={true}
              multiple={true}
              component={SelectInput}
            /> */}
            <label >Project/Complex Name</label><span className='color-mandatory'> * </span>
        
        <Field
              name="projectNameA"
              placeholder="Project/Complex Name"
              type="text"
              options={projectName()}
              search={true}
              multiple={true}
              component={SelectInput}
            />

            <label >Locality</label>
            <Field
              name="localityA"
              placeholder="Locality"
              type="text"
              options={locality()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />

            <label >Possession By Date</label>
            <Field
              name="to"
              type="text"
              component={DateInput}
              dateFormat="dd-MM-yyyy"
              placeholder="Possession By Date "
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />


            <br />

            <Button
              color="blue"
              type="submit"
              disabled={submitting}
            >
              Search
            </Button>
            <Button
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="teal"

              type="button"
              onClick={()=>props.closeModal()}

            >
              Cancel
            </Button>
          </Form>
          <br/>
            </div>
            </div>
            </div>
  );
};
const mapStateToProps = state => {
 
  return {
    state
  };
};
export default connect(
  mapStateToProps,
   {searchProjectRs,closeModal}
)(
  reduxForm({
    form: "ProjectRSearch", // a unique identifier for this form
    destroyOnUnmount: false
    // validate
  })(ProjectRSearch)
);
