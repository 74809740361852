import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import writtenNumber from 'written-number';
import {capitalizeFirst} from "../functions/Functions";
import {searchPropResiRents} from '../../actions/propresiAction';
import { fetchCompanysForPropListRR } from "../../actions/staticAction";
import { closeModal } from "../../actions/modalAction";

import {  Form, Button,   } from 'semantic-ui-react';
import NumberInput from "../form/NumberInput";
import SelectInput from '../form/SelectInput';
import DateInput from '../form/DateInput';

import {
 
  propertyFor,
  carParkingCovered,
  carParkingOpen,
  entry,
} from './DataS';
import {
  advertisedByForSearch,
  propertyType,
  bedroom,
  bathroom,
  additionalRooms,
  furnishedStatus,
  floorNo,
  locality,
  sorting
} from './Data';

const PropResiRentSearch = props => {
  useEffect(() => {
    const datePickers = document.getElementsByClassName("react-datepicker__input-container");
    Array.from(datePickers).forEach((el => el.childNodes[0].setAttribute("readOnly", true)))
    },[])
    
  const { handleSubmit,  reset, submitting } = props;
  const { maxRent, minRent } =
    props.state.form &&
    props.state.form.PropResiRentSearch !== undefined &&
    props.state.form.PropResiRentSearch.values !== undefined &&
    props.state.form.PropResiRentSearch.values;

    const projectName = () => {
      return props.state.projectRs.projectRsForData.map(value => ({
        key: value.projectName,
        text: `${value.projectName}` ,
        value: value.projectName,style:{fontSize:'18px'}
      }));
    };

    const onSubmit = values => {
      const offsetRR = 0
      const offsetStaticRR = 0
      const offsetStaticDealerRR = 0
      const {
      limitRR,
      limitStaticRR,
      limitStaticDealerRR,
  } = props.state.propresis;
  props.searchPropResiRents(values ,limitRR, offsetRR,limitStaticRR,offsetStaticRR);
  props.fetchCompanysForPropListRR(limitStaticDealerRR,offsetStaticDealerRR )

  };

  return (
    <div style={{maxWidth:'700px',margin:'auto'}}>

      {/**/}
      <div className='margin-auto-H' >
            <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
              {/*  */}
              Search Residential Property Available for Rent
          {/*  */}
          </div>
          </div>
          {/**/}
        
          <div className="card-form mt-10">
          <div  >

          <Form
          onSubmit={handleSubmit(onSubmit)}
          >
 #  Leave All Field Blank (Clear Values) to View All Available Properties 
           <br /><br />
            <Button
            color="teal"
            type="button"
            onClick={()=>props.closeModal()}

            >
            Cancel
            </Button>
            
            <Button
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="blue"
              type="submit"
              disabled={submitting}
            >
              Search
            </Button>
          <br /><br />
          <label >Sort By</label>
          <Field
            //search={true}
            name="sorting"
            type="text"
            component={SelectInput}
            options={sorting()}
            placeholder="Sort By"
          />
          <label >Property For</label>
             <Field
              name="propertyFor"
              placeholder="Property For"
              type="text"
              options={propertyFor()}
              //search={true}
              component={SelectInput}
            />


            <label >Property Type</label>
            <Field
              name="propertyTypeA"
              placeholder="Property Type"
              type="text"
              options={propertyType()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label >Bedroom</label>
            <Field
              name="bedroomA"
              placeholder="Bedroom"
              type="text"
              options={bedroom()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label >Bathroom</label>
            <Field
              name="bathroomA"
              placeholder="Bathroom"
              type="text"
              options={bathroom()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label >Additional Rooms</label>
            <Field
              name="additionalRoomsA"
              placeholder="Additional Rooms"
              type="text"
              options={additionalRooms()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />

            <label >Furnished Status</label>
            <Field
              name="furnishedStatusA"
              placeholder="Furnished Status"
              type="text"
              options={furnishedStatus()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />

            <label >
              Minimum Rent{' '}
              <span>
                ({minRent && capitalizeFirst(writtenNumber(minRent, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
                
              </span>
            </label>
            <Field
              name="minRent"
              placeholder="Minimum Rent"
              type="number"
              component={NumberInput}
            />
            <label >
              Maximum Rent{' '}
              <span>
                ({maxRent && capitalizeFirst(writtenNumber(maxRent, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
                
              </span>
            </label>
            <Field
              name="maxRent"
              placeholder="Maximum Rent"
              type="number"
              component={NumberInput}
            />

            <label >Entry</label>
            <Field
              name="entryA"
              placeholder="Entry"
              type="text"
              options={entry()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />

            <label >Covered Car Parking</label>
            <Field
              name="carParkingCoveredA"
              placeholder="Covered Car Parking"
              type="text"
              options={carParkingCovered()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label >Open Car Parking</label>
            <Field
              name="carParkingOpenA"
              placeholder="Open Car Parking"
              type="text"
              options={carParkingOpen()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label >Floor No</label>
            <Field
              name="floorNoA"
              placeholder="Floor No"
              type="text"
              options={floorNo()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />

            <label >Society/Apartment Name</label>
            <Field
              name="projectNameA"
              placeholder="Society/Apartment Name"
              type="text"
              options={projectName()}
              search={true}
              multiple={true}
              component={SelectInput}
            />

            <label >Locality</label>
            <Field
              name="localityA"
              placeholder="Locality"
              type="text"
              options={locality()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label >Property Created FROM</label>
              <Field
                name="from"
                type="text"
                component={DateInput}
                dateFormat="dd-MM-yyyy"
                placeholder="Property Created FROM"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
              <label >Property Created TO</label>
              <Field
                name="to"
                type="text"
                component={DateInput}
                dateFormat="dd-MM-yyyy"
                placeholder="Property Created TO"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
<label >Advertised By</label>
            <Field
              name="advertisedBy"
              placeholder="Advertised By"
              type="text"
              options={advertisedByForSearch()}
              //search={true}
              //multiple={true}
              component={SelectInput}
            />
            
            <br />

            <Button
              color="blue"
              type="submit"
              disabled={ submitting}
            >
              Search
            </Button>
            <Button
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="teal"

              type="button"
              onClick={()=>props.closeModal()}

            >
              Cancel
            </Button>
          </Form>
          <br/><br/><br/>
            </div>
            </div>
            </div>
  );
};
const mapStateToProps = state => {
  return {
    state,
    userA: state.auth.userA,
  };
};
export default connect(
  mapStateToProps,
  {searchPropResiRents,closeModal ,fetchCompanysForPropListRR}
)(
  reduxForm({
    form: 'PropResiRentSearch', // a unique identifier for this form
    destroyOnUnmount: false
    // validate
  })(PropResiRentSearch)
);
