import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import writtenNumber from 'written-number';
import {capitalizeFirst} from "../functions/Functions";
import {searchPropComSales} from '../../actions/propcomAction';
import { fetchCompanysForPropListCS } from "../../actions/staticAction";
import { closeModal } from "../../actions/modalAction";
import {  Form, Button, } from 'semantic-ui-react';
import NumberInput from "../form/NumberInput";
import SelectInput from '../form/SelectInput';
import DateInput from '../form/DateInput';

import {
  propertyType,
  //propertyFor,
  booleanOption,
  furnishedStatus,
  advertisedByForSearch,
  floorNo,
  locality,
  sorting
} from './Data';
import {
  
  bedroom,
  bathroom,
  entry,
} from './DataS';

const PropComSaleSearch = props => {
  useEffect(() => {
    const datePickers = document.getElementsByClassName("react-datepicker__input-container");
    Array.from(datePickers).forEach((el => el.childNodes[0].setAttribute("readOnly", true)))
    },[])
    
  const { maxPrice, minPrice } =
    props.state.form &&
    props.state.form.PropComSaleSearch !== undefined &&
    props.state.form.PropComSaleSearch.values !== undefined &&
    props.state.form.PropComSaleSearch.values;
  const { handleSubmit,  reset, submitting } = props;

  const projectName = () => {
    return props.state.projectCs.projectCsForData.map(value => ({
      key: value.projectName,
      text: `${value.projectName}` ,
      value: value.projectName,style:{fontSize:'18px'}
    }));
  };
  
  const onSubmit = values => {
    const offsetCS = 0
    const offsetCSF = 0
    const offsetStaticCS = 0
    const offsetStaticDealerCS = 0
    const {
      limitCS,
      limitCSF,
      limitStaticCS,
      limitStaticDealerCS,
  } = props.state.propresis;
  
  props.searchPropComSales(values, limitCS, offsetCS ,  limitCSF, offsetCSF , limitStaticCS, offsetStaticCS );
  props.fetchCompanysForPropListCS(limitStaticDealerCS,offsetStaticDealerCS )
    
  };

  return (
    <div style={{maxWidth:'700px',margin:'auto'}}>

      {/**/}
      <div className='margin-auto-H' >
            <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
              {/*  */}
              Search Commercial Property Available for Sale
          {/*  */}
          </div>
          </div>
          {/**/}
        
          <div className="card-form mt-10">
          <div  >

          <Form
          onSubmit={handleSubmit(onSubmit)}
          >
             #  Leave All Field Blank (Clear Values) to View All Available Properties 
           <br /><br />
          <Button
              color="teal"
              type="button"
              onClick={()=>props.closeModal()}

            >
              Cancel
            </Button>
            
            <Button
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="blue"
              type="submit"
              disabled={submitting}
            >
              Search
            </Button>
          <br /><br />
          <label >Sort By</label>
          <Field
            //search={true}
            name="sorting"
            type="text"
            component={SelectInput}
            options={sorting()}
            placeholder="Sort By"
          />


            <label >Property Type</label>
            <Field
              name="propertyTypeA"
              placeholder="Property Type"
              type="text"
              options={propertyType()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label >Rooms</label>
            <Field
              name="bedroomA"
              placeholder="Rooms"
              type="text"
              options={bedroom()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label >Washrooms</label>
            <Field
              name="bathroomA"
              placeholder="Washrooms"
              type="text"
              options={bathroom()}
              //search={true}
              multiple={true}
              component={SelectInput} />


            <label >Furnished Status</label>
            <Field
              name="furnishedStatusA"
              placeholder="Furnished Status"
              type="text"
              options={furnishedStatus()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />

            <label >
              Minimum Price{' '}
              <span>
                ({minPrice && capitalizeFirst(writtenNumber(minPrice, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
                
              </span>
            </label>
            <Field
              name="minPrice"
              placeholder="Minimum Price"
              type="number"
              component={NumberInput}
            />
            <label >
              Maximum Price{' '}
              <span>
                ({maxPrice && capitalizeFirst(writtenNumber(maxPrice, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
                
              </span>
            </label>
            <Field
              name="maxPrice"
              placeholder="Maximum Price"
              type="number"
              component={NumberInput}
            />
            <label >Minimum Carpet Area</label>
            <Field
              name="minArea"
              placeholder="Minimum Carpet Area"
              type="number"
              component={NumberInput}
            />
            <label >
              Maximum Carpet Area
            </label>
            <Field
              name="maxArea"
              placeholder="Maximum Carpet Area"
              type="number"
              component={NumberInput}
            />

            <label >Common Washroom</label>
            <Field
              name="washroomCommon"
              placeholder="Common Washroom"
              type="text"
              options={booleanOption()}
              component={SelectInput}
            />
            <label >Personal Washroom</label>
            <Field
              name="washroomPersonal"
              placeholder="Personal Washroom"
              type="text"
              options={booleanOption()}
              component={SelectInput}
            />
            <label >Personal Pantry</label>
            <Field
              name="pantryPersonal"
              placeholder="Personal Pantry"
              type="text"
              options={booleanOption()}
              component={SelectInput}
            />
            <label >Power BackUp</label>
            <Field
              name="powerBackUp"
              placeholder="Power BackUp"
              type="text"
              options={booleanOption()}
              component={SelectInput}
            />
            <label >Lifts In The Tower</label>
            <Field
              name="liftsInTheTower"
              placeholder="Lifts In The Tower"
              type="text"
              options={booleanOption()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label >Car Parking</label>
            <Field
              name="parking"
              placeholder=" Parking"
              type="text"
              options={booleanOption()}
              component={SelectInput}
            />
            <label >Reserve Car Parking</label>
            <Field
              name="reserveParking"
              placeholder="Reserve Car Parking"
              type="text"
              options={booleanOption()}
              component={SelectInput}
            />
            <label >Visitor Car Parking</label>
            <Field
              name="visitorParking"
              placeholder="Visitor Car Parking"
              type="text"
              options={booleanOption()}
              component={SelectInput}
            />


            <label >Entry</label>
            <Field
              name="entryA"
              placeholder="Entry"
              type="text"
              options={entry()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />


            <label >Floor No</label>
            <Field
              name="floorNoA"
              placeholder="Floor No"
              type="text"
              options={floorNo()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />

            <label >Complex/Project Name</label>
            <Field
              name="projectNameA"
              placeholder="Complex/Project Name"
              type="text"
              options={projectName()}
              search={true}
              multiple={true}
              component={SelectInput}
            />

            <label >Locality</label>
            <Field
              name="localityA"
              placeholder="Locality"
              type="text"
              options={locality()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label >Property Created FROM</label>
            <Field
              name="from"
              type="text"
              component={DateInput}
              dateFormat="dd-MM-yyyy"
              placeholder="Property Created FROM"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            <label >Property Created TO</label>
            <Field
              name="to"
              type="text"
              component={DateInput}
              dateFormat="dd-MM-yyyy"
              placeholder="Property Created TO"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
<label >Advertised By</label>
            <Field
              name="advertisedBy"
              placeholder="Advertised By"
              type="text"
              options={advertisedByForSearch()}
              //search={true}
              //multiple={true}
              component={SelectInput}
            />

            <br />

            <Button
              color="blue"
              type="submit"
              disabled={ submitting}
            >
              Search
            </Button>
            <Button
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="teal"

              type="button"
              onClick={()=>props.closeModal()}

            >
              Cancel
            </Button>
          </Form>
          <br/><br/><br/>
            </div>
            </div>
            </div>
  );
};
const mapStateToProps = state => {
  return {
    state,
    userA: state.auth.userA,
  };
};
export default connect(
  mapStateToProps,
   {searchPropComSales,closeModal ,fetchCompanysForPropListCS}
)(
  reduxForm({
    form: 'PropComSaleSearch', // a unique identifier for this form
    destroyOnUnmount: false
    // validate
  })(PropComSaleSearch)
);
