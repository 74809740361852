import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import {capitalizeFirst} from "../functions/Functions";
import writtenNumber from 'written-number';
import {createPropCom} from '../../actions/propcomAction';
import {fetchAllProjectCs} from '../../actions/projectCAction';
import { withRouter } from 'react-router-dom';

import {
  Form,
  Button,
} from 'semantic-ui-react';
import TextInput from '../form/TextInput';
import TextArea from '../form/TextArea';
import SelectInput from '../form/SelectInput';
import DateInput from '../form/DateInput';
import NumberInput from "../form/NumberInput";
//import { projectName } from './DataProjectName';

import {
  advertisedBy,
  propertyForOption,
  propertyType,
  brokerResponse,
  category,
  areaUnit,
  bedroom,
  bathroom,

  furnishedStatus,
  interior,
  // ac,
  // fan,
  status,
  loanOfferedBy,
  typeOfOwnership,
  facing,
  entry,
  Overlooking,
  
  floorNo, 
  totalFloors,
  locality,
  // city,
  //************
  booleanOption,
  frequency,
companyLease,
agreement,
} from './Data';

import {
  composeValidators,
  combineValidators,
  isRequired,
} from 'revalidate';
import {isValidEmail,isValidPhone,} from "../validator/revalidator";

const validate = combineValidators({
  phone1:composeValidators(
    isRequired('Phone1'),
    isValidPhone({}),

  )(),
  phone2:isValidPhone({}),
  email:isValidEmail({}),
  whatsapp: isValidPhone({}),
  advertisedBy:isRequired('You Are'),
  propertyFor:isRequired('Property For'),
  
});



class PropComCreate extends Component {

  componentDidMount() {
    if(this.props.state.projectCs.projectCsForData.length <1){
      this.props.fetchAllProjectCs();
    }
   
      const datePickers = document.getElementsByClassName("react-datepicker__input-container");
      Array.from(datePickers).forEach((el => el.childNodes[0].setAttribute("readOnly", true)))
   }
  

  projectName = () => {
    return this.props.state.projectCs.projectCsForData.map(value => ({
      key: value.projectName,
      text: `${value.projectName}` ,
      value: value.projectName,style:{fontSize:'18px'}
    }));
  };
  projectId = () => {
    return this.props.state.projectCs.projectCsForData.map(value => ({
      key: value._id,
      text: value.projectName,
      value: value._id,style:{fontSize:'18px'}
    }));
  };
  onSubmit = value => {
    const userS = this.props.userA !== null && this.props.userA._id
    const values={...value,userS}
    this.props.createPropCom(values, this.props.history);
  };
  backButtonHandler =()=>{
    this.props.history.goBack()
  }
  render() {

    const { aproxValueOfWork, expectedPrice,expectedRent,
      securityDeposit,otherCharges,maintenanceCharges,propertyFor} =
      this.props.state.form &&
      this.props.state.form.PropComCreate !== undefined &&
      this.props.state.form.PropComCreate.values !== undefined &&
      this.props.state.form.PropComCreate.values;

    const { handleSubmit, pristine, reset, submitting } = this.props;
    const renderSaleOrRentField = ()=>{
      if(propertyFor==='SALE'){
        return (
         < React.Fragment>
<label >
                  Demand{' '}
                  <span>
                  ({expectedPrice && capitalizeFirst(writtenNumber(expectedPrice, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
                    
                  </span>
                </label><span className='color-mandatory'> * </span>
                <Field
                  name="expectedPrice"
                  placeholder="Demand"
                  type="number"
                  component={NumberInput}
                />
         </React.Fragment>
        )
      }else{
        if(propertyFor==='RENT'||propertyFor==='PG ACCOMMODATION'){
          return (
            < React.Fragment>
<label >
                  Rent Demand{' '}
                  <span>
                    ({expectedRent && capitalizeFirst(writtenNumber(expectedRent, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
                    
                  </span>
                </label><span className='color-mandatory'> * </span>
                <Field
                  name="expectedRent"
                  placeholder="Rent Demand"
                  type="number"
                  component={NumberInput}
                />
                <label >
                  SecurityDeposit{' '}
                  <span>
                    ({securityDeposit && capitalizeFirst(writtenNumber(securityDeposit, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
                    
                  </span>
                </label>
                <Field
                  name="securityDeposit"
                  placeholder="SecurityDeposit"
                  type="number"
                  component={NumberInput}
                />
                <label >
                  Maintenance Charges{' '}
                  <span>
                    ({maintenanceCharges && capitalizeFirst(writtenNumber(maintenanceCharges, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
              
                  </span>
                </label>
                <Field
                  name="maintenanceCharges"
                  placeholder="Maintenance Charges"
                  type="number"
                  component={NumberInput}
                />
                <label >Frequency Of Maintenance Charges</label>
                <Field
                  name="unitMaintenanceCharges"
                  placeholder="Monthly,Quarterly,Yearly,One-Time"
                  type="text"
                  options={frequency()}
                  search={true}
                  component={SelectInput}
                />
                <label >
                  Other Charges{' '}
                  <span>
                    ({otherCharges && capitalizeFirst(writtenNumber(otherCharges, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
                    
                  </span>
                </label>
                <Field
                  name="otherCharges"
                  placeholder="Other Charges"
                  type="number"
                  component={NumberInput}
                />
                <label >Frequency Of Other Charges</label>
                <Field
                  name="unitOtherCharges"
                  placeholder="Monthly,Quarterly,Yearly,One-Time"
                  type="text"
                  options={frequency()}
                  search={true}
                  component={SelectInput}
                />


                <label >Company Lease</label>
                <Field
                  name="companyLease"
                  placeholder="Company Lease"
                  type="text"
                  options={companyLease()}
                  //search={true}
                  component={SelectInput}
                />
                <label >Agreement Type</label>
                <Field
                  name="agreement"
                  placeholder="Agreement Type"
                  type="text"
                  options={agreement()}
                  //search={true}
                  component={SelectInput}
                />

         </React.Fragment>
          )
        }
      }
    }
    
    
    //*************** */

      return (
        <div style={{maxWidth:'700px',margin:'auto'}}>
          
          {/*  */}
        <div className='margin-auto' >
              <div   style={{fontSize:'1.2rem',fontWeight:'bold',padding:'5px',textAlign:'center'}}  >
              Post your Property so that potential Buyer or Tenant can contact 
          you for your property 
              
           </div>
           </div>
           {/*  */}
           <div className='margin-auto-H'>
<div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
 Create Commercial Property To Sell or Rent your Property 

</div>
</div>


           {/*  */}
        
        <div className="card border">
          <div className=" label" >



              <Form  style={{ padding: 10 }}  onSubmit={handleSubmit(this.onSubmit)}>

              <Button
            color="teal"
            type="button"
            onClick={()=>this.backButtonHandler()}

          >
            Cancel
          </Button>
              <Button
                color="orange"
                type="button"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Clear Values
              </Button>
              <br /><br />
          <div>(All field marked with <span style={{color:'red'}}><b>*</b></span> are required)
          </div>
              <label >You Are</label>{' '}<span style={{color:'red'}}><b>*</b></span>
              <Field
                name="advertisedBy"
                placeholder="You Are"
                type="text"
                options={advertisedBy()}
                //search={true}
                component={SelectInput}
              />
                <label >Owner Name </label>
                <Field
                  name="name"
                  type="text"
                  component={TextInput}
                  placeholder="Owner Name"
                />
                <label >Mobile</label>{' '}<span style={{color:'red'}}><b>*</b></span>
                <Field
                  name="phone1"
                  type="text"
                  component={TextInput}
                  placeholder="Mobile Number"
                />
                <label >Phone2</label>
                <Field
                  name="phone2"
                  type="text"
                  component={TextInput}
                  placeholder="Phone2"
                />
                <label >Email</label>
                <Field
                  name="email"
                  type="text"
                  component={TextInput}
                  placeholder="Email"
                />
                <label >Whatsapp ID</label>
                <Field
                  name="whatsapp"
                  type="text"
                  component={TextInput}
                  placeholder="Whatsapp ID"
                />


                <label >Broker Response</label>
                <Field
                  name="brokerResponse"
                  placeholder="Broker Response"
                  type="text"
                  options={brokerResponse()}
                  //search={true}
                  component={SelectInput}
                />


                <label >Property For</label>{' '}<span style={{color:'red'}}><b>*</b></span>
                          <Field
                            name="propertyFor"
                            placeholder="Property For"
                            type="text"
                            options={propertyForOption()}
                            //search={true}
                            component={SelectInput}
                          />
                          <label >Category</label>
                          <Field
                            name="category"
                            placeholder="Category"
                            type="text"
                            options={category()}
                            //search={true}
                            component={SelectInput}
                          />
                <label >Property Type</label>
                <Field
                  name="propertyType"
                  placeholder="Property Type"
                  type="text"
                  options={propertyType()}
                  //search={true}
                  component={SelectInput}
                />
                 {renderSaleOrRentField()}

                 <label >Carpet Area In Square Foot</label>
                <Field
                  type="number"
                  component={NumberInput}
                  placeholder="Carpet Area"
                  name="carpetArea"
                />


                <label >Length In Feet</label>
                <Field
                  type="number"
                  component={NumberInput}
                  placeholder="Length In Feet"
                  name="length"
                />
                <label >Breadth In Feet</label>
                <Field
                  type="number"
                  component={NumberInput}
                  placeholder="Breadth In Feet"
                  name="breadth"
                />

                 <label >Area</label> 
        <Field
        name="area"
        type="text"
        component={TextInput}
        placeholder="Area"
        />
        <label >Unit Of Area</label>
        <Field
        name="areaUnit"
        placeholder="Unit Of Area"
        type="text"
        options={areaUnit()}
        //search={true}
        component={SelectInput}
        />
                <label >Rooms</label>
                <Field
                  name="bedroom"
                  placeholder="Rooms"
                  type="text"
                  options={bedroom()}
                  //search={true}
                  component={SelectInput}
                />
                <label >Washrooms</label>
                <Field
                  name="bathroom"
                  placeholder="Washrooms"
                  type="text"
                  options={bathroom()}
                  //search={true}
                  component={SelectInput}
                />
                <label >Store</label>
                <Field
                  name="store"
                  placeholder="Store"
                  type="text"
                  options={booleanOption()}
                  component={SelectInput}
                />

                

                <label >Furnished Status</label>
                <Field
                  name="furnishedStatus"
                  placeholder="Furnished Status"
                  type="text"
                  options={furnishedStatus()}
                  //search={true}
                  component={SelectInput}
                />
                <label >Interior</label>
                <Field
                  name="interior"
                  placeholder="Interior"
                  type="text"
                  options={interior()}
                  //search={true}
                  component={SelectInput}
                />
                <label >
                  Aprox Value Of Interior Work{' '}
                  <span>
                    ({aproxValueOfWork && capitalizeFirst(writtenNumber(aproxValueOfWork, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
                    
                  </span>
                </label>
                <Field
                  type="number"
                  component={NumberInput}
                  placeholder="Aprox Value Of Work"
                  name="aproxValueOfWork"
                />

                <label >AC</label>
                <Field
                  name="ac"
                  placeholder="Number of AC if any"
                  type="text"
                  component={TextInput}
                />
                <label >Fan</label>
                <Field
                  name="fan"
                  placeholder="Number of Fan if any"
                  type="text"
                  component={TextInput}
                />

                <label >Other Items</label>
                <Field
                  type="text"
                  component={TextInput}
                  placeholder="Other Items"
                  name="others"
                />


                <label >Facing</label>
                <Field
                  name="facing"
                  placeholder="Facing"
                  type="text"
                  options={facing()}
                  //search={true}
                  component={SelectInput}
                />
                <label >Entry</label>
                <Field
                  name="entry"
                  placeholder="Entry"
                  type="text"
                  options={entry()}
                  //search={true}
                  component={SelectInput}
                />
                <label >Overlooking</label>
                <Field
                  name="Overlooking"
                  placeholder="Overlooking"
                  type="text"
                  options={Overlooking()}
                  //search={true}
                  component={SelectInput}
                />
                <label >Common Washroom</label>
                <Field
                  name="washroomCommon"
                  placeholder="Common Washroom"
                  type="text"
                  options={booleanOption()}
                  component={SelectInput}
                />
                <label >Personal Washroom</label>
                <Field
                  name="washroomPersonal"
                  placeholder="Personal Washroom"
                  type="text"
                  options={booleanOption()}
                  component={SelectInput}
                />
                <label >Personal Pantry</label>
                <Field
                  name="pantryPersonal"
                  placeholder="Personal Pantry"
                  type="text"
                  options={booleanOption()}
                  component={SelectInput}
                />
                <label >Car Parking</label>
                <Field
                  name="parking"
                  placeholder=" Parking"
                  type="text"
                  options={booleanOption()}
                  component={SelectInput}
                />
                <label >Reserve Car Parking</label>
                <Field
                  name="reserveParking"
                  placeholder="Reserve Car Parking"
                  type="text"
                  options={booleanOption()}
                  component={SelectInput}
                />
                <label >Visitor Car Parking</label>
                <Field
                  name="visitorParking"
                  placeholder="Visitor Car Parking"
                  type="text"
                  options={booleanOption()}
                  component={SelectInput}
                />
                <label >Floor No</label>
                <Field
                  name="floorNo"
                  placeholder="Floor No"
                  type="text"
                  options={floorNo()}
                  //search={true}
                  component={SelectInput}
                />
                <label >Total Floors</label>
                <Field
                  name="totalFloors"
                  placeholder="Total Floors"
                  type="text"
                  options={totalFloors()}
                  //search={true}
                  component={SelectInput}
                />
                <label >Lifts In The Tower</label>
                <Field
                  name="liftsInTheTower"
                  placeholder="Lifts In The Tower"
                  type="text"
                  options={booleanOption()}
                  //search={true}
                  //multiple={true}
                  component={SelectInput}
                />
                <label >Type Of Ownership</label>
                <Field
                  name="typeOfOwnership"
                  placeholder="Type Of Ownership"
                  type="text"
                  options={typeOfOwnership()}
                  //search={true}
                  component={SelectInput}
                />
                <label >Status</label>
                <Field
                  name="status"
                  placeholder="Status"
                  type="text"
                  options={status()}
                  //search={true}
                  component={SelectInput}
                />


                <label >Possession</label>
                <Field
                  name="possession"
                  type="text"
                  component={DateInput}
                  placeholder="Possession"
                  dateFormat="dd-MM-yyyy"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />


                <label >Loan Offered By</label>
                <Field
                  name="loanOfferedBy"
                  placeholder="Loan Offered By"
                  type="text"
                  options={loanOfferedBy()}
                  //search={true}
                  component={SelectInput}
                />
                <label >Complex/Project Name</label>
                <Field
                  name="projectName"
                  placeholder="Complex/Project Name"
                  type="text"
                  options={this.projectName()}
                  search={true}
                  component={SelectInput}
                />
                {/* <label >Project Id</label>
                <Field
                  name="projectId"
                  placeholder="Project Id"
                  type="text"
                  options={this.projectId()}
                  search={true}
                  component={SelectInput}
                /> */}

                <label >Address</label>
                <Field
                  name="address"
                  type="text"
                  component={TextInput}
                  placeholder="Address"
                />
                <label >Locality</label>
                <Field
                  name="locality"
                  placeholder="Locality"
                  type="text"
                  options={locality()}
                  search={true}
                  component={SelectInput}
                />
                {/* <label>City/District</label>{' '}<span style={{color:'red'}}><b>*</b></span>
                <Field
                //search={true}
                name="city"
                type="text"
                component={SelectInput}
                options={city()}
                placeholder="City/District"
                /> */}
                <label >About/USP of Property</label>
                <Field
                  name="description"
                  placeholder="About/USP of Property"
                  type="text"
                  component={TextArea}
                  rows={3}
                />

                <br />

                <Button
                  color="olive"
                  type="submit"
                  disabled={pristine || submitting}
                >
                  Submit
                </Button>
                <Button
                  color="orange"
                  type="button"
                  disabled={pristine || submitting}
                  onClick={reset}
                >
                  Clear Values
                </Button>
              </Form>
              <br/><br/><br/>
            </div>
            </div>
            </div>
      );


  }
}
const mapStateToProps = state => {
  return {
    state,
    userA: state.auth.userA
  };
};
export default connect(
  mapStateToProps,
  {createPropCom,fetchAllProjectCs}
)(
  reduxForm({
    form: 'PropComCreate', // a unique identifier for this form
    validate
  })(withRouter(PropComCreate))
);
