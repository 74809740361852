import React from "react";
import { connect } from "react-redux";
 
import AlertModal from './AlertModal';
 import LoginModal from './LoginModal';
 import PassResetModal from './PassResetModal';
 import ContactUsModal from './ContactUsModal';
 import ImageZoomModal from './ImageZoomModal'; 
 import ShowImagesModal from './ShowImagesModal';
 import ShowImagesCompanyModal from './ShowImagesCompanyModal';
 import ShowVideoModal from './ShowVideoModal';
 import ContactDetailForPropListModal from './ContactDetailForPropListModal';
 
 import MessageModal from './MessageModal';
import SearchCompanyModal from "./SearchCompanyModal";
import SearchCompanyModalFH from "./SearchCompanyModalFH";
import SearchFinancerModal from "./SearchFinancerModal";

import SearchBuilderModal from "./SearchBuilderModal";

import SearchBuilderFloorSaleModal from "./SearchBuilderFloorSaleModal";
import SearchPropResiSaleModal from "./SearchPropResiSaleModal";
import SearchPropResiSaleModalFH from "./SearchPropResiSaleModalFH";

import SearchPropResiRentModal from "./SearchPropResiRentModal";
import SearchPropResiRentModalFH from "./SearchPropResiRentModalFH";

import SearchPropComSaleModal from "./SearchPropComSaleModal";
import SearchPropComSaleModalFH from "./SearchPropComSaleModalFH";

import SearchPropComRentModal from "./SearchPropComRentModal";
import SearchPropComRentModalFH from "./SearchPropComRentModalFH";

import SearchReqComModal from "./SearchReqComModal";
import SearchReqComUnlockedModal from "./SearchReqComUnlockedModal";
import SearchReqComModalFH from "./SearchReqComModalFH";

import SearchReqResiModal from "./SearchReqResiModal";
import SearchReqResiUnlockedModal from "./SearchReqResiUnlockedModal";
import SearchReqResiModalFH from "./SearchReqResiModalFH";


import SearchProjectRModal from "./SearchProjectRModal";
import SearchProjectRModalFH from "./SearchProjectRModalFH";

import SearchProjectCModal from "./SearchProjectCModal";
import SearchProjectCModalFH from "./SearchProjectCModalFH";

import StaticSearchModal from "./StaticSearchModal";
import SearchStaticPropertyModal from "./SearchStaticPropertyModal";
import SearchStaticDealerModal from "./SearchStaticDealerModal";

import SearchContactReqsByPartyModal from "./SearchContactReqsByPartyModal";
import SearchContactReqsForDealerModal from "./SearchContactReqsForDealerModal";
import SearchAdByDealerModal from "./SearchAdByDealerModal";

import TermsAndConditionsModal from './TermsAndConditionsModal'
import CookiesPolicyModal from './CookiesPolicyModal'
import PrivacyPolicyModal from './PrivacyPolicyModal'

import SearchBookAdPropResiModal from './SearchBookAdPropResiModal'
import SearchBookAdPropComModal from './SearchBookAdPropComModal'
import SearchBookAdProjectComModal from './SearchBookAdProjectComModal'
import SearchBookAdProjectResiModal from './SearchBookAdProjectResiModal'

const modalLookup = {
  AlertModal, 
   LoginModal,
   PassResetModal,
   ContactUsModal,
   ImageZoomModal,
   ShowImagesModal,
   ShowImagesCompanyModal,
   ShowVideoModal,
   ContactDetailForPropListModal,
  
   MessageModal,
  SearchCompanyModal,
  SearchCompanyModalFH,
  SearchFinancerModal,

  SearchBuilderModal,

  SearchBuilderFloorSaleModal,
  SearchPropResiSaleModal,
  SearchPropResiSaleModalFH,

  SearchPropResiRentModal,
  SearchPropResiRentModalFH,


  SearchPropComSaleModal,
  SearchPropComSaleModalFH,

  SearchPropComRentModal,
  SearchPropComRentModalFH,

  SearchReqResiModal,
  SearchReqResiUnlockedModal,
  SearchReqResiModalFH,

  SearchReqComModal,
  SearchReqComUnlockedModal,
  SearchReqComModalFH,

  SearchProjectCModal,
  SearchProjectCModalFH,

  SearchProjectRModal,
  SearchProjectRModalFH,

  StaticSearchModal,
  SearchStaticPropertyModal,
  SearchStaticDealerModal,

  SearchAdByDealerModal,
  SearchContactReqsForDealerModal,
  SearchContactReqsByPartyModal,

  SearchBookAdPropResiModal,
  SearchBookAdPropComModal,
  SearchBookAdProjectComModal,
  SearchBookAdProjectResiModal,

  CookiesPolicyModal,
  PrivacyPolicyModal,
  TermsAndConditionsModal,
  
};

const mapState = state => ({
  currentModal: state.modals
});

const ModalManager = ({ currentModal }) => {
  let renderedModal;

  if (currentModal) {
    const { modalType, modalProps } = currentModal;
    const ModalComponent = modalLookup[modalType];

    renderedModal = <ModalComponent {...modalProps} />;
  }
  return <span>{renderedModal}</span>;
};

export default connect(mapState)(ModalManager);
