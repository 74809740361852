import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
//import Script from 'react-load-script';

import { searchBuilders } from '../../actions/builderAction';
import { closeModal } from '../../actions/modalAction';
import { withRouter } from 'react-router-dom';

import {
  Divider,
  
  Form,
  Button,
  
  Header
} from 'semantic-ui-react';
import TextInput from '../form/TextInput';

import SelectInput from '../form/SelectInput';
import DateInput from '../form/DateInput';

import { city,locality, } from './Data';
const BuilderSearch = props => {
  useEffect(() => {
    const datePickers = document.getElementsByClassName("react-datepicker__input-container");
    Array.from(datePickers).forEach((el => el.childNodes[0].setAttribute("readOnly", true)))
    },[])
    
  const { handleSubmit,  reset, submitting,  } = props;

  const onSubmit = values => {
    const offset = 0
const { limit} = props.state.builders;
    props.searchBuilders(values, limit,offset);
    //props.closeModal();
  };
  return (
    <div style={{maxWidth:'700px',margin:'auto'}}>

    {/**/}
    <div className='margin-auto-H' >
          <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
            {/*  */}
            Search Builders
        {/*  */}
        </div>
        </div>
        {/**/}
        <div className="card-form mt-10">
          <div  >

          <Form
          onSubmit={handleSubmit(onSubmit)}
          >
             #  Leave All Field Blank (Clear Values) to View All Available Builders 
           <br /><br />


            <Button
            color="teal"
            type="button"
            onClick={()=>props.closeModal()}

            >
            Cancel
            </Button>
            
            <Button
               color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="blue"
              type="submit"
              disabled={submitting}
            >
              Search
            </Button>
          <br /><br />
            <Header sub color="teal" content="Search Comanies By Name" />
            <Divider fitted />
            <br />
            <label >Name Of Builder/Contact Person </label>
            <Field
              name="name"
              type="text"
              component={TextInput}
              placeholder="Name Of Builder/Contact Person"
            />
            <Header sub color="teal" content="Or Search Comanies By Phone" />
            <Divider fitted />
            <br />
            <label >Phone Of Builder/Contact Person</label>
            <Field
              name="mobile"
              type="text"
              component={TextInput}
              placeholder="Phone Of Builder/Contact Person"
            />
            <Header sub color="teal" content="Or Search Comanies By Email" />
            <Divider fitted />
            <br />
            <label >Email Of Builder/Contact Person</label>
            <Field
              name="email"
              type="text"
              component={TextInput}
              placeholder="Email Of Builder/Contact Person"
            />
            <Header sub color="teal" content="Or Search Comanies By " />
            <Divider fitted />
            <br />
            <label >Builder's Website</label>
            <Field
              name="website"
              type="text"
              component={TextInput}
              placeholder="Builder's Website"
            />
            <label >Operating Since</label>
            <Field
              name="operatingSince"
              type="text"
              component={DateInput}
              dateFormat="dd-MM-yyyy"
              placeholder="Operating Since"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            {/* <label >Type of Dealings</label>
            <Field
              //search={true}
              multiple={true}
              name="typeOfDealings"
              type="text"
              component={SelectInput}
              options={typeOfDealings()}
              placeholder="Type of Dealings"
            />
            <label >Types of properties deals in</label>
            <Field
              //search={true}
              multiple={true}
              name="typeOfPropertyDealsIn"
              type="text"
              component={SelectInput}
              options={typeOfProp()}
              placeholder="Types of properties deals in"
            /> */}
            <label >Locality</label>
            <Field
              name="localityA"
              placeholder="Locality"
              type="text"
              options={locality()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label >City/District</label>
            <Field
              //search={true}
              name="city"
              type="text"
              component={SelectInput}
              options={city()}
              placeholder="City/District"
            />

            <br />

            <Button
              color="blue"
              type="submit"
              disabled={submitting}
            >
              Search
            </Button>
            <Button
              color="orange"
              type="button"
              disabled={submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="teal"

              type="button"
              onClick={()=>props.closeModal()}

            >
              Cancel
            </Button>
          </Form>
          <br/>
            </div>
            </div>
            </div>
  );
};

const mapStateToProps = (state)=>{
  return{
    state,
  }
}
export default connect(
  mapStateToProps,
  { searchBuilders, closeModal }
)(
  reduxForm({
    form: 'BuilderSearch', // a unique identifier for this form
    destroyOnUnmount: false

    //validate
  })(withRouter(BuilderSearch))
);
