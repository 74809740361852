import React, {useEffect} from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
//import writtenNumber from "written-number";

import {fetchStaticDealerAdByDealer} from "../../actions/staticAction";
import { closeModal } from "../../actions/modalAction";
import {  Form, Button,  } from "semantic-ui-react"; 
//import TextInput from "../form/TextInput";
import SelectInput from "../form/SelectInput";
import DateInput from "../form/DateInput";

import { 
  // adCategory,
  // adForOption,
  // adLocationProperty,
  adLocationDealer,
  // adSubLocation,
  // position,
  // locality,

} from './Data';



const StaticSearchDealer = props => {
  useEffect(() => {
    const datePickers = document.getElementsByClassName("react-datepicker__input-container");
    Array.from(datePickers).forEach((el => el.childNodes[0].setAttribute("readOnly", true)))
    },[])
    
  const { handleSubmit,  reset, submitting } = props;

  const onSubmit = values => {
    const offsetD = 0
const { limitD} = props.state.statics;
    props.fetchStaticDealerAdByDealer(values, limitD,offsetD);
  };
  return (
    <div style={{maxWidth:'700px',margin:'auto'}}>

      {/**/}
      <div className='margin-auto-H' >
            <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
              {/*  */}
              Search Booked Static Dealer Ad
          {/*  */}
          </div>
          </div>
          {/**/}
        
          <div className="card-form mt-10">
          <div  >

          <Form
          onSubmit={handleSubmit(onSubmit)}
          >
 #  Leave All Field Blank (Clear Values) to View All Booked Static Dealer Ad 
           <br /><br />
            

            <Button
            color="teal"
            type="button"
            onClick={()=>props.closeModal()}

            >
            Cancel
            </Button>
            
            <Button
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="blue"
              type="submit"
              disabled={submitting}
            >
              Search
            </Button>
          <br /><br />

          <label > Ad Location</label>
            <Field
            search={true}
            name="adLocationA"
            type="text"
            component={SelectInput}
            multiple={true}
            options={adLocationDealer()}
            placeholder="Ad Location "
            />
            {/*  */}
            <label >Lower Started On Date</label>
            <Field
              name="startL"
              type="text"
              component={DateInput}
              placeholder= "Lower Started On Date To Search Static Dealer Ad Between Two Dates"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            <label >Higher Started On Date</label>
            <Field
              name="startH"
              type="text"
              component={DateInput}
              placeholder="Higher Started On Date To Search Static Dealer Ad Between Two Dates"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            <label >Lower Expired On Date</label>
            <Field
              name="expireL"
              type="text"
              component={DateInput}
              placeholder= "Lower Expired On Date To Search Static Dealer Ad Between Two Dates"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            <label >Higher Expired On Date</label>
            <Field
              name="expireH"
              type="text"
              component={DateInput}
              placeholder="Higher Expired On Date To Search Static Dealer Ad Between Two Dates"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />




            <br />

            <Button
              color="blue"
              type="submit"
              disabled={submitting}
            >
              Search
            </Button>
            <Button
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="teal"

              type="button"
              onClick={()=>props.closeModal()}

            >
              Cancel
            </Button>
          </Form>
          <br/>
            </div>
            </div>
            </div>
  );
  
};
const mapStateToProps = state => {
  return {
    state
  };
};
export default connect(
  mapStateToProps,
   {fetchStaticDealerAdByDealer,closeModal}
)(
  reduxForm({
    form: "StaticSearchDealer", // a unique identifier for this form
    destroyOnUnmount: false
    // validate
  })(StaticSearchDealer)
);
