import React, { Component } from "react";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import {
  Button,
} from "semantic-ui-react";

class UnlockContactDetail extends Component {

  
  handleReqResiForUnlockContact = async (values) => {
    
    if((Date.parse(values.creditExpiredOn) > Date.now()) && (values.balanceCreditReq > values.creditUsed)){
    
      const id=values.id;

      try { 
      
        const res = await axios.put(`/api/ReqResiEditForUnlockContact`, {id:id,creditUsed:values.creditUsed});
    
        if (res.data.msg === 'errorAlready') { 
        toastr.error('Already Unlocked');
        }
        if (res.data.msg === 'errorCreditExpire') { 
          toastr.error("Your Credit Have Expired");
          this.props.handleModalClose()
          }
          if (res.data.msg === 'errorCredit') { 
            toastr.error("You Do Not Have Enough Credit To Unlock Contact Details");
            this.props.handleModalClose()
            this.props.handleRefresh() 
          }
        
        
        if (res.data.msg ==='success'){
        this.props.handleRefresh()
        this.props.handleModalClose()
        toastr.success('Contact Unlocked Successfully');
        }
        
      } catch (error) {
     
        toastr.error("Oops", "Something went wrong.Please Try Again CreateAd");
      }
    }else if (Date.parse(values.creditExpiredOn) < Date.now()){
      toastr.error("Your Credit Have Expired");
  
    }else{
      toastr.error("You Do Not Have Enough Credit To Unlock Contact Details");
      this.props.handleModalClose()
      this.props.handleRefresh()
    }
    
  };
  

  render() {
    
const {
  balanceCreditReq,
  creditUsed,
} = this.props.valueCUnlock 



    //*************** */
    return (
      <div className = 'maindiv-768' >
        {/*  */}
<div className='card-attached border-radius-top border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >
	
	<div className='heading-2'>
	<span  > Unlock Contact Details</span>
	</div>

	</div>

	<div className='card-attached border-radius-bottom border-sb pxy-10 mb-010'  >

  		{/*  */}
      
      <div >
  { (
  <div className='row'>
  <div className='side flex-70'>
  <b>Total Credit Available</b>
  </div>
  <div className='main flex-30'>
  <b>{balanceCreditReq}{' '}</b>{' '}
  </div>

  </div>
  )}
  </div>

  <div >
  {creditUsed >=0 && (
  <div className='row'>
  <div className='side flex-70'>
  <b>Credit Will Be Used</b>
  </div>
  <div className='main flex-30'>
  <b>{creditUsed}{' '}</b>{' '}
  </div>

  </div>
  )}
  </div>
  <br/>
  <div className = 'disflex-paginator'>
  <Button
  //  floated="right"
  color="orange"
  onClick={()=> this.handleReqResiForUnlockContact (this.props.valueCUnlock)}

  >
  Unlock Contact Detail
  </Button>
  <Button
  //  floated="right"
  color="blue"
  onClick={this.props.handleModalClose}
  >
  cancel
  </Button>
  </div>
        {/*  */}
        </div>
      </div>

    );
  }
}

export default UnlockContactDetail
