import React, {useEffect} from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";


import {fetchAdByDealer} from "../../actions/dealerSuggestedAction";
import { closeModal } from "../../actions/modalAction";
import {  Form, Button, } from "semantic-ui-react";

import SelectInput from "../form/SelectInput";
import DateInput from "../form/DateInput";

import {
  propertyFor,
  adForOption,
  projectType,
} from './DataS';
import {  locality,
} from "../functions/DataFunctions";


const SearchAdByDealer = props => {
  useEffect(() => {
    const datePickers = document.getElementsByClassName("react-datepicker__input-container");
    Array.from(datePickers).forEach((el => el.childNodes[0].setAttribute("readOnly", true)))
    },[])

  const { handleSubmit,  reset, submitting } = props;

  const {  adFor} =
    props.state.form &&
    props.state.form.SearchAdByDealer !== undefined &&
    props.state.form.SearchAdByDealer.values !== undefined &&
    props.state.form.SearchAdByDealer.values;

  const onSubmit = values => {
    const offset = 0
const { limit} = props.state.dealerSuggesteds;
    props.fetchAdByDealer(values, limit,offset);
  };
  return (
    
          <div style={{maxWidth:'700px',margin:'auto'}}>

    {/**/}
    <div className='margin-auto-H' >
          <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
            {/*  */}
            Search Booked Advertisements
        {/*  */}
        </div>
        </div>
        {/**/}
      
        <div className="card-form mt-10">
          <div  >

          <Form
          onSubmit={handleSubmit(onSubmit)}
          >
             #  Leave All Field Blank (Clear Values) to View All Booked Advertisements 
           <br /><br />

            <Button
            color="teal"
            type="button"
            onClick={()=>props.closeModal()}

            >
            Cancel
            </Button>
            
            <Button
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="blue"
              type="submit"
              disabled={submitting}
            >
              Search
            </Button>
            <br/><br/>

          <label > Ad For </label>
            <Field
             // search={true}
              name="adFor"
              type="text"
              component={SelectInput}
             // multiple={true}
              options={adForOption()}
              placeholder="Ad For  "
            /> 
            <label > Project/Property Type </label>
            <Field
            //  search={true}
              name="projectType"
              type="text"
              component={SelectInput}
             // multiple={true}
              options={projectType()}
              placeholder="Project/Property Type"
            /> 
            { adFor === "Property" &&
            (<>
            <label > Property For </label>
            <Field
             // search={true}
              name="propertyFor"
              type="text"
              component={SelectInput}
             // multiple={true}
              options={propertyFor()}
              placeholder="Property For"
            />
            </>) }

            <label >Locality</label>
        <Field
        name="locality"
        placeholder="Locality"
        type="text"
        options={locality(props.state.appParameters.localitys)}
      search={true}
        component={SelectInput}
        />
            {/*  */}
            <label >Started On</label>
            <Field
              name="startedOn"
              type="text"
              component={DateInput}
              placeholder= "Ad Started On Date To Search Ad"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            <label >Expired On</label>
            <Field
              name="expiredOn"
              type="text"
              component={DateInput}
              placeholder="Ad Expired On Date To Search Ad"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />




            <br />

            <Button
              color="blue"
              type="submit"
              disabled={submitting}
            >
              Search
            </Button>
            <Button
              color="orange"
              type="button"
              disabled={submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="teal"
              type="button"
              onClick={()=>props.closeModal()}

            >
              Cancel
            </Button>
          </Form>
          <br/>
            </div>
            </div>
            </div>
  );
};
const mapStateToProps = state => {
  return {
    state
  };
};
export default connect(
  mapStateToProps,
   {fetchAdByDealer,closeModal}
)(
  reduxForm({
    form: "SearchAdByDealer", // a unique identifier for this form
    destroyOnUnmount: false
    // validate
  })(SearchAdByDealer)
);
